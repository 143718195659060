import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';

import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import { DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

type ContactSupportDialogProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
};

export const ContactSupportDialog = ({
  isDialogOpen,
  onDialogClose
}: ContactSupportDialogProps) => {
  const { t } = useTranslation('contactSupport');
  const handleCloseDialog = () => onDialogClose();

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      disableScrollLock
    >
      <DialogTitle className="text-secondary-600">{t('header')}</DialogTitle>

      <DialogContent className="py-[24px] px-[32px]">
        <Typography component="div" className="text-lkh-text-info mb-[24px]">
          {t('description1')}
        </Typography>

        <div className="flex flex-wrap justify-center items-center gap-[32px] py-[24px] px-[32px] mt-[32px] bg-surface-60 rounded">
          <div>
            <div className="flex">
              <FmdGoodIcon />
              <div className="ml-[8px]">
                <Typography component={'div'} variant="bodySMBold" className="text-text-80">
                  {t('contact.location.title')}
                </Typography>
                <Typography
                  component={'div'}
                  variant="bodySMRegular"
                  className="text-text-60 mt-[8px]"
                >
                  {t('contact.location.detail1')}
                  <br />
                  {t('contact.location.detail2')}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <LocalPhoneIcon />
              <div className="ml-[8px]">
                <Typography component={'div'} variant="bodySMBold" className="text-text-80 ">
                  {t('contact.number.title')}
                </Typography>
                <Typography
                  component={'div'}
                  variant="bodySMRegular"
                  className="text-text-60 mt-[8px]"
                >
                  {t('contact.number.detail')}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <EmailIcon />
              <div className="ml-[8px]">
                <Typography
                  component={'a'}
                  variant="bodySMBoldLink"
                  className="text-text-80"
                  href={`mailto:${t('contact.email.title')}`}
                >
                  {t('contact.email.title')}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
