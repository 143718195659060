import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { DarkTooltip } from 'lkh-portal-ui-library';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

export const ValidationError = () => {
  const { state } = useHealthInsuranceContext();

  if (state.validationResults.size > 0) {
    const getErrorList = () => {
      const listItems = Array.from(state.validationResults).map(([key, value]) => (
        <ListItem sx={{ p: 0.5 }} key={key}>
          <Typography className="text-sm font-normal">
            {!value?.success && value?.error?.issues[0].message}
          </Typography>
        </ListItem>
      ));
      return <List sx={{ p: 0 }}>{listItems}</List>;
    };

    const getFirstError = () => {
      const firstEntry = state.validationResults.entries().next().value;

      if (firstEntry) {
        const [, result] = firstEntry;

        if (
          result &&
          !result.success &&
          result.error &&
          result.error.issues &&
          result.error.issues.length > 0
        ) {
          return result.error.issues[0].message;
        }
      }

      return null;
    };

    return (
      <div className="flex items-center justify-end gap-[8px] text-danger-60 w-full max-w-full overflow-hidden">
        <DarkTooltip
          children={
            <Typography className="flex items-center gap-[4px] whitespace-nowrap text-sm font-normal overflow-hidden min-w-0">
              <span className="inline-block text-ellipsis overflow-hidden max-w-fit w-full text-right">
                {getFirstError()}
              </span>
              <span>
                {state.validationResults.size > 1 && ` + ${state.validationResults.size - 1}`}
              </span>
              <ErrorOutlineIcon
                className="text-danger-60 flex-shrink-0"
                sx={{ width: 16, height: 16 }}
              />
            </Typography>
          }
          title={getErrorList()}
          arrow
          placement="top"
        />
      </div>
    );
  }

  return null;
};
