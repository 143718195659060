import { Typography } from '@mui/material';
import classNames from 'classnames';
import {
  Body,
  Checkbox,
  Dialog,
  DownloadIcon,
  Icon,
  Loader,
  LoaderType,
  PrimaryButton,
  Props,
  TertiaryButton
} from 'lkh-portal-ui-library';
import { EVENT_IDS } from 'pages/DigitalOrderPage/constants';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const DownloadOfferDialog = ({
  isOpen,
  hasConsent,
  onClose,
  onDownload,
  onConsentClick,
  onDownloadDisclaimerDocument
}: {
  isOpen: boolean;
  hasConsent: boolean;
  onClose: () => void;
  onConsentClick: (val: boolean) => void;
  onDownload: () => Promise<void>;
  onDownloadDisclaimerDocument: () => Promise<void>;
}) => {
  const { t } = useTranslation('wizardSidebar');
  const [isDownloading, setIsDownloading] = useState(false);

  async function handleDownloadDisclaimer(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    setIsDownloading(true);
    try {
      await onDownloadDisclaimerDocument();
    } finally {
      setIsDownloading(false);
    }
  }

  async function handleDownloadOffer() {
    setIsDownloading(true);
    try {
      await onDownload();
    } finally {
      setIsDownloading(false);
    }
  }

  const renderConsentDescription = () => {
    return (
      <Body small regular>
        <Trans
          i18nKey={'wizardSidebar:downloadOffer.disclaimerCheckboxText'}
          components={[
            <a
              onClick={handleDownloadDisclaimer}
              className="m-px text-logo-500 cursor-pointer inline underline"
            />
          ]}
        />
      </Body>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} dialogMainClassName={'relative'}>
      <div className="text-center s:text-left w-full space-y-6">
        <Typography variant="bodyMDRegular">{t('downloadOffer.title')}</Typography>
        <div
          className={classNames('p-m rounded-md  text-left', {
            'bg-lkh-color-primary-40': hasConsent,
            'bg-background': !hasConsent
          })}
        >
          <Checkbox
            id={EVENT_IDS.DATA_PROTECTION_CHECKBOX}
            onChange={(value) => onConsentClick(value)}
            checked={hasConsent}
            description={renderConsentDescription()}
          />
        </div>
      </div>
      <div className={'pt-l flex justify-end'}>
        <TertiaryButton className="mr-s s:mb-s" onClick={onClose}>
          <Body small medium>
            {t('downloadOffer.cancel')}
          </Body>
        </TertiaryButton>
        <PrimaryButton
          id={EVENT_IDS.DOWNLOAD_OFFER}
          onClick={handleDownloadOffer}
          disabled={!hasConsent}
        >
          {isDownloading ? (
            <Loader className="mr-s" color={Props.ShadeColors.White} type={LoaderType.Circular} />
          ) : (
            <Icon className="mr-s text-white" icon={<DownloadIcon />} />
          )}
          <Body small medium className="text-white">
            {t('downloadOffer.download')}
          </Body>
        </PrimaryButton>
      </div>
    </Dialog>
  );
};
