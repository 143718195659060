import {
  CancelablePromise,
  ConfigService,
  Tariff,
  TariffCards,
  TariffSectionEnum
} from 'models/extension-generated';
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { differenceInCalendarYears } from 'date-fns';
import { formModelGet } from 'lkh-portal-ui-library';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';

export type TabType = TariffSectionEnum.STATIONAER | TariffSectionEnum.ZAHN;

type TariffSectionContextType = {
  isLoading: boolean;
  tariffCards: TariffCards;
  tab: TabType;
  setTab: (type: TabType) => void;
};

import { partnerField } from 'models';

export const TariffSectionContext = createContext<TariffSectionContextType>(null!);

export const TariffContextProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('common');
  const [tab, setTab] = useState<TabType>(TariffSectionEnum.STATIONAER);

  const [tariffCards, setTariffCards] = useState<TariffCards>({});
  const [isLoading, setIsLoading] = useState(false);
  const { state, partnerId, reducer } = useHealthInsuranceContext();
  const birthDateKey = partnerField(partnerId, 'birthDate');
  const tariffsKey = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.selectedTariffs'
  );

  const tariffs: Array<Tariff> = formModelGet(state.model, tariffsKey);
  const from = formModelGet(state.model, birthDateKey);
  const insuranceStart = formModelGet(state.model, 'applicationStart');
  const age =
    differenceInCalendarYears(new Date(insuranceStart as string), new Date(from)) || undefined;

  const request = useRef<{ promise: CancelablePromise<TariffCards> }>({ promise: null! });
  const cancelCurrentRequest = () => {
    request.current?.promise?.cancel();
  };

  const fetchTariffCardsFn = async () => {
    cancelCurrentRequest();

    setIsLoading(true);
    try {
      request.current.promise = ConfigService.getTariffCards({
        insuranceBegin: insuranceStart,
        birthDate: from
      });
      const response = await request.current.promise;

      setTariffCards(response);
      setIsLoading(false);

      const cards = response.cards || [];

      tariffs.forEach((tariff) => {
        const isSTariff = tariff.id.toLowerCase().includes('_s');
        const isLTariff = !isSTariff;
        const tariffCard = cards.find((card) => card.tariffs?.includes(tariff.id));
        const isLTariffRemoved = isLTariff && tariffCard?.stablePremiumVisibility === false;

        if (!tariffCard) return;

        // if tariff is not available in new cards configuration then it is replaced with its S variant
        // only should happen for L tariffs
        if (isLTariffRemoved) {
          // do nothing tariff is available in new cards confiuguration
          // remove tariff from selected tariffs
          reducer.removeTariff({
            partnerId,
            tariff: {
              id: tariff.id
            }
          });
          reducer.giveTariff({
            partnerId,
            tariff: {
              id: tariff.id.replace('_L', '_S'),
              section: tariff.section
            }
          });
          return;
        }

        // in this case there selected tariff was S but cards definition has changed and L variant is now required
        if (isSTariff && tariffCard.stablePremiumVisibility) {
          reducer.removeTariff({
            partnerId,
            tariff: {
              id: tariff.id
            }
          });
          reducer.giveTariff({
            partnerId,
            tariff: {
              section: tariff.section,
              id: tariff.id.replace('_S', '_L')
            }
          });
          return;
        }
      });
    } catch (error) {
      const genericError = t('common:toastMessages.somethingWentWrong');
      toast.error(genericError);
    }
  };

  useEffect(() => {
    fetchTariffCardsFn();
  }, []);

  useEffect(() => {
    fetchTariffCardsFn();
  }, [age]);

  return (
    <TariffSectionContext.Provider
      value={{
        setTab,
        tab,
        tariffCards,
        isLoading
      }}
    >
      {children}
    </TariffSectionContext.Provider>
  );
};

export function useTariffContext(): TariffSectionContextType {
  const tariffContext = useContext(TariffSectionContext);

  if (!tariffContext) {
    throw new Error(
      'To use "useTariffSectionContext" some of the parent components must be in <TariffContextProvider>'
    );
  }

  return tariffContext;
}
