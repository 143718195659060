import { Country } from 'models/extension-generated';

/**
 * Finds and returns a specific field value from a country object in the provided array of countries
 * based on a search criteria.
 *
 * @template T - The key of the return field in the Country object.
 * @param {Country[]} countries - An array of country objects to search through.
 * @param {keyof Country} searchField - The field in the country object to search by (e.g., 'code').
 * @param {string | undefined} searchValue - The value to match in the `searchField`.
 * @param {T} returnField - The field whose value you want to retrieve from the matched country object (e.g., 'name').
 * @returns {Country[T] | undefined} - The value of the specified `returnField` from the matched country object, or `undefined` if no match is found.
 *
 * @example
 * const countries: Country[] = [
 *   { code: 'US', name: 'United States', lifestreamCode: 'LS_US' },
 *   { code: 'CA', name: 'Canada', lifestreamCode: 'LS_CA' },
 *   // more countries...
 * ];
 *
 * const countryName = findCountryField(countries, 'code', 'US', 'name');
 * console.log(countryName); // Output: 'United States'
 *
 * const lifestreamCode = findCountryField(countries, 'code', 'CA', 'lifestreamCode');
 * console.log(lifestreamCode); // Output: 'LS_CA'
 */
export function findCountryField<T extends keyof Country>(
  countries: Country[],
  searchField: keyof Country,
  searchValue: string | undefined,
  returnField: T
): Country[T] | undefined {
  if (searchValue) {
    const country = countries.find((country) => country[searchField] === searchValue);
    return country ? country[returnField] : undefined;
  }
  return undefined;
}
