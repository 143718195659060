import { Loader, ThemeProvider } from 'lkh-portal-ui-library';
import React, { Suspense } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { deDE } from '@mui/x-date-pickers/locales';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigContextProvider } from 'contexts/ConfigContext/ConfigContext';
import de from 'date-fns/locale/de';
import { DigitalOrderPage } from 'pages/DigitalOrderPage';
import { DoneStep } from 'pages/DigitalOrderPage/subpages/DoneStep';
import { theme } from 'styles/theme/theme';
import { WizardRoutesEnum } from 'utils/actions';

const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText;
const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <React.StrictMode>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={de}
        localeText={germanLocale}
      >
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <QueryClientProvider client={client}>
              <ConfigContextProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<DigitalOrderPage />} />
                    <Route
                      path={`${WizardRoutesEnum.Done}/:applicationId`}
                      element={<DoneStep />}
                    />
                  </Routes>
                </BrowserRouter>
              </ConfigContextProvider>
            </QueryClientProvider>
          </Suspense>
        </ThemeProvider>
      </LocalizationProvider>
    </React.StrictMode>
  );
}

export default App;
