import Chip from '@mui/material/Chip';
import { TariffDescription } from 'components/TariffDescription';
import { Tooltip } from 'lkh-portal-ui-library';

export const TariffLabel = ({ label, tooltip }: { label: string; tooltip: string }) => {
  return (
    <Tooltip
      tooltipContent={tooltip && <TariffDescription description={tooltip} />}
      showArrow
      tooltipDebounce={300}
      tooltipOffset={7}
      placement="right"
    >
      <Chip
        label={label}
        className="rounded-[8px] text-white-100"
        variant="filled"
        color="primary"
      />
    </Tooltip>
  );
};
