import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import classNames from 'classnames';
import { DarkTooltip, useApplicationHelpers } from 'lkh-portal-ui-library';
import { useMemo } from 'react';

import { Partner, PersonRoleEnum, TariffCalculationResult } from 'models/extension-generated';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatPrice } from 'utils/string';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography
} from '@mui/material';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useTariffQuery } from 'hooks/useTariffQuery';
import { useWizardNavigation } from 'hooks/useWizardNavigation';
import { WizardRoutesEnum } from 'utils/actions';
import { useApplicationValidation } from '../../hooks/useApplicationValidation';
import { TariffRow } from './TariffRow/TariffRow';

type PartnerSummaryProps = {
  partner: Partner;
  isLoading: boolean;
  isMobile: boolean;
  price: number;
  tariffs: Array<TariffCalculationResult>;
};

export const PartnerSummary = ({
  partner,
  price,
  tariffs,
  isMobile,
  isLoading = false
}: PartnerSummaryProps) => {
  const { state, partnerId, selectPartner, reducer } = useHealthInsuranceContext();
  const { currentScreen } = useWizardNavigation();
  const { clearRole, createPartner } = reducer;
  const { isValidating } = useApplicationValidation(state, reducer, partnerId);
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const { tariffs: allTariffsList } = useTariffQuery();
  const insuredPartners = useMemo(() => getPartnersByRole(PersonRoleEnum.INSURED_PERSON), [state]);
  const isSelected = useMemo(() => partnerId === partner.id!, [partnerId]);
  const formatedPartner = formatNameSidebar(partner);

  function formatNameSidebar(partner?: Partner): string {
    // Check if both firstname and lastname are present
    if (partner?.firstname && partner?.lastname) {
      return `${partner.lastname}, ${partner.firstname}`;
    }
    // Check if only firstname is present
    else if (partner?.firstname) {
      return `${partner.firstname}`;
    }
    // Check if only lastname is present
    else if (partner?.lastname) {
      return `${partner.lastname}`;
    }
    // Return an empty string if no names are present
    return '';
  }

  function handleDeletePartner(): void {
    const currentRoles = partner.roles || [];
    if (isSelected) {
      const prevIndex = insuredPartners.findIndex((p) => p.id === partnerId) - 1;
      selectPartner(insuredPartners[prevIndex].id!);
    }

    if (currentRoles.includes(PersonRoleEnum.POLICY_HOLDER)) {
      clearRole({ role: PersonRoleEnum.POLICY_HOLDER });
      createPartner({ role: PersonRoleEnum.POLICY_HOLDER });
    }
    reducer.removePartner({ id: partner.id! });
  }

  const isRemoveDisabled = useMemo(() => {
    return (
      isValidating || insuredPartners.length === 1 || currentScreen !== WizardRoutesEnum.PrivateData
    );
  }, [state]);

  const textColorClassname = isMobile ? 'text-white' : '';
  const selectedTariffs = partner.applicationInformation?.tariffInformation?.selectedTariffs || [];
  return (
    <>
      <hr className="m-0 bg-text-20" />

      <Accordion
        className="bg-[transparent]"
        disableGutters
        elevation={0}
        square
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={
            'bg-transparent border border-text-40 border-b-[1px] border-t-0 border-r-0 border-l-0 border-solid px-[16px] py-[0px] mb-[16px] overflow-auto'
          }
        >
          <span className={`flex w-full items-center justify-start ${textColorClassname}`}>
            <Typography
              variant="bodySMRegular"
              component={'span'}
              translate="no"
              className={classNames('flex-none', 'w-1/2', 'max-w-[200px]', 'truncate', {
                'text-white': isMobile,
                'text-primary': !isMobile
              })}
            >
              <DarkTooltip title={formatedPartner} arrow placement="left" noWrap>
                <span>{formatedPartner}</span>
              </DarkTooltip>
            </Typography>
            <div className="grow text-right mr-[26px]">
              {!isLoading && (
                <Typography variant="bodySMBold" className={textColorClassname}>
                  {price ? formatPrice(price) : '-,--'}
                </Typography>
              )}
              <IconButton
                color="error"
                size="small"
                onClick={handleDeletePartner}
                disabled={isRemoveDisabled}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </div>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {selectedTariffs
            .sort((a, b) => (a.sequenceOfOrder! > b.sequenceOfOrder! ? 1 : -1))
            .map((tariff) => {
              const calculationTariff = tariffs.find(({ tariffId }) => tariffId === tariff.id);

              return (
                <TariffRow
                  isMobile={isMobile}
                  key={`partner-summary-${tariff.id}`}
                  calculation={calculationTariff}
                  tariff={tariff}
                  tariffsList={allTariffsList}
                />
              );
            })}
          {selectedTariffs.length === 0 && (
            <Typography variant="bodySMRegular">Kein Tarif ausgewählt</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
