import { Components, Theme } from '@mui/material/styles';

import i18n from 'services/translation';

import { fieldHeight } from '../constants';

export const muiAutocompleteOverride: Components<Omit<Theme, 'components'>>['MuiAutocomplete'] = {
  defaultProps: {
    clearIcon: false,
    classes: {
      groupLabel: 'text-[14px] text-text-80 p-[8px] font-bold m-0 ',
      option: 'text-[14px] text-text-80 p-[8px]'
    },
    loadingText: i18n.t<string>('common:loading'),
    noOptionsText: i18n.t<string>('common:noOptionsText'),
    getOptionLabel: (option) => option.label || '',
    isOptionEqualToValue: (option, value) => option.value === value.value
  },
  styleOverrides: {
    root: {
      height: fieldHeight
    }
  }
};
