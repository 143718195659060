import { Typography } from '@mui/material';
import classNames from 'classnames';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useApplicationCalculate } from 'hooks/useApplicationCalculate';
import { useWizardNavigation } from 'hooks/useWizardNavigation';
import {
  Body,
  Icon,
  Label,
  Loader,
  LoaderType,
  MaterialAccordion,
  UserCircleIcon,
  useApplicationHelpers,
  useQuestionsContext
} from 'lkh-portal-ui-library';
import { PersonRoleEnum, TariffCalculationResult } from 'models/extension-generated';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/string';
import { DownloadButton } from '../DownloadButton';
import { PartnerSummary } from './PartnerSummary';

export const SidebarContent = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { t } = useTranslation('wizardSidebar');
  const { state } = useHealthInsuranceContext();
  const { questions } = useQuestionsContext();
  const { currentScreen } = useWizardNavigation();
  const { data, isLoading, error } = useApplicationCalculate(state.model, questions);
  const { getPartnersByRole } = useApplicationHelpers(state.model);

  function getPartnerPrice(id: string): number {
    const partner = data?.partners.find(({ partnerId }) => partnerId === id);

    if (error) {
      return 0;
    }

    if (partner && partner.calculationMonthly) {
      return partner.calculationMonthly.totalAmount || 0;
    }

    return 0;
  }

  function getPartnerTariffs(id: string): Array<TariffCalculationResult> {
    const partner = data?.partners.find(({ partnerId }) => partnerId === id);
    if (partner && partner.tariffCalculations) {
      return partner.tariffCalculations;
    }

    return [];
  }

  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const overallSum = data?.calculationMonthly?.amount
    ? formatPrice(data?.calculationMonthly?.amount || 0)
    : '-,--';

  return (
    <>
      <div className="text-right mb-s w-full">
        <div>
          {isLoading ? (
            <Loader type={LoaderType.Circular} />
          ) : (
            <Typography variant="bodyMDBold" className="m-v-0">
              {overallSum}
            </Typography>
          )}
        </div>
        <div>
          <Typography variant="bodyXSBold">{t('calculation.frequency.monthly')}</Typography>
        </div>
        <div>
          <Typography variant="bodyMDRegular" className={classNames('text-neutral-500', 'm-v-0')}>
            {t('calculation.insuredPerson.count', { count: insuredPartners.length })}
            <span className="text-secondary m-l-10">
              {insuredPartners.map((partner) => (
                <Icon key={`icon-${partner.id}`} icon={<UserCircleIcon />} className="m-l-4" />
              ))}
            </span>
          </Typography>
        </div>
        <div className="px-s py-s">
          <DownloadButton currentPage={currentScreen} />
        </div>
      </div>

      <div className="w-full">
        {insuredPartners.map((partner) => (
          <PartnerSummary
            isMobile={isMobile}
            key={partner.id}
            partner={partner}
            price={getPartnerPrice(partner.id!)}
            tariffs={getPartnerTariffs(partner.id!)}
            isLoading={isLoading}
          />
        ))}
      </div>
      {/* TODO enable when available */}
      {/* {error && message && (
        <div className={classNames('p-24 bg-error-500', 'styles.errorContainer')}>
          <Icon icon={<AlertIcon />} />
          <div className={classNames('m-l-16', 'styles.textContainer')}>
            <Body medium className="d-block">
              {t('calculation.errorTitle')}
            </Body>
            <Body className="d-block m-t-8" small regular>
              {message}
            </Body>
          </div>
        </div>
      )} */}
    </>
  );
};

export const Sidebar = () => {
  const { t } = useTranslation('wizardSidebar');
  const { state } = useHealthInsuranceContext();
  const { questions } = useQuestionsContext();
  const { data, isLoading, error } = useApplicationCalculate(state.model, questions);
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const overallSum = error ? '-,--' : formatPrice(data?.calculationMonthly?.amount || 0);

  const renderMobileHeader = () => {
    return (
      <div className="flex justify-between w-full">
        <div className="mr-s">
          <Label
            medium
            className={classNames('text-white', 'm-v-0', 'styles.insuredPersonSummary')}
          >
            {t('calculation.insuredPerson.count', { count: insuredPartners.length })}
            <span className="text-white m-l-10">
              {insuredPartners.map((partner) => (
                <Icon key={`icon-${partner.id}`} icon={<UserCircleIcon />} className="m-l-4" />
              ))}
            </span>
          </Label>
        </div>

        <div className="flex">
          <div>
            {isLoading ? (
              <Loader type={LoaderType.Circular} />
            ) : (
              <Body small className="text-white" bold>
                {overallSum}
              </Body>
            )}
          </div>
          <div>
            <Body className="text-white mr-m">&nbsp;{t('calculation.frequency.monthly')}</Body>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="hidden m:block p-l">
        <SidebarContent />
      </div>
      {/* Added accordionWrapper */}
      <div className="flex flex-col w-full s:w-auto m:hidden">
        <MaterialAccordion
          accordionProps={{
            sx: {
              backgroundColor: '#00635f',
              border: 'none'
            }
          }}
          summaryProps={{
            sx: {
              backgroundColor: '#00635f'
            }
          }}
          accordionDetailsProps={{
            sx: {
              backgroundColor: '#00635f',
              padding: '0',
              paddingTop: '10px'
            }
          }}
          header={renderMobileHeader()}
        >
          <SidebarContent isMobile />
        </MaterialAccordion>
      </div>
    </>
  );
};
