import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { TabType, useTariffContext } from 'contexts/TariffSectionContext';
import { applicationField } from 'models';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useURLParams = () => {
  const didRun = useRef(false);
  const { reducer, partnerId } = useHealthInsuranceContext();
  const { giveTariff, updateValue } = reducer;
  const { isLoading, tariffCards, setTab } = useTariffContext();
  const [searchParams] = useSearchParams();
  const cards = tariffCards.cards || [];

  // Extracting query parameters from URL
  const tariffId = searchParams.get('tarif')?.toLowerCase();
  const agentId = searchParams.get('vermittlerId')?.toLowerCase();
  const subAgentId = searchParams.get('untervermittlerId')?.toLowerCase();
  const externeId = searchParams.get('externeId')?.toLowerCase();

  // Helper function to handle tariff selection
  const handleTariffSelection = () => {
    if (!tariffId) return;

    const prefilledTariff = cards.find((card) => card.tariffs?.includes(tariffId.toUpperCase()));

    if (prefilledTariff?.section) {
      setTab(prefilledTariff.section as TabType);
      giveTariff({
        partnerId,
        tariff: {
          id: prefilledTariff.tariffs?.find((tariff) => tariff.toLowerCase() === tariffId),
          section: prefilledTariff.section
        }
      });
    }
  };

  // Helper function to update application field values
  const updateApplicationField = ({ key, value }: Parameters<typeof updateValue>[0]) => {
    if (value) {
      updateValue({
        key,
        value
      });
    }
  };

  useEffect(() => {
    // Only run the effect if data is loaded and the effect hasn't run yet
    if (!isLoading && !didRun.current && cards.length > 0) {
      handleTariffSelection();
      updateApplicationField({ key: applicationField('agentId'), value: agentId });
      updateApplicationField({ key: applicationField('subAgentId'), value: subAgentId });
      updateApplicationField({ key: applicationField('externalId'), value: externeId });

      didRun.current = true;
    }
  }, [isLoading]);
};
