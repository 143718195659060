import moment from 'moment';
import { format, addMonths, setDate, differenceInYears, parseISO } from 'date-fns';

const ADULTHOOD_AGE = 18;

export const formatDateToGermanForm = (date: string): string => {
  return date.replaceAll('/', '.');
};

export const formatDateAndTime = (date: Date | number | string): string => {
  return format(new Date(date), 'dd. MM. yyyy HH:mm');
};

export const formatTableDate = (date: Date | number | string): string => {
  return format(new Date(date), 'dd MMM yyyy');
};

export const formatTextDate = (date: Date | number | string): string => {
  return format(new Date(date), 'dd. MMMM yyyy');
};

export const formatShortDate = (date: Date | number | string): string => {
  if (!date) return '';
  const momentDate = moment(date).toISOString();
  return format(new Date(momentDate), 'dd.MM.yyyy');
};

export const formatDate = (date: Date | number | string): string => {
  if (!date) return '';
  const momentDate = moment(date).toISOString();
  return format(new Date(momentDate), 'yyyy/MM/dd');
};

export const formatGermanDate = (date: Date | number | string): string => {
  if (!date) return '';
  return format(new Date(date), 'dd.MM.yyyy');
};

export const formatMontnAndYear = (date: Date | number | string): string => {
  if (!date) return '';
  return format(new Date(date), 'MMM yyyy');
};

export function calculateAge(dateOfBirth?: string | null, fromDate?: string): number {
  const dateOfBirthObject = moment(dateOfBirth);

  let fromDateObject = moment();

  if (fromDate) {
    fromDateObject = moment(fromDate).endOf('year');
  }

  return fromDateObject.diff(dateOfBirthObject, 'years');
}

export function formatInput(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function dateValid(date: string): boolean {
  const testDate = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(date);
  // check for date length because of FF bug
  if (testDate) {
    return true;
  }
  return false;
}

export function removeYearsFromDate(date: Date, years: number): string {
  const currDate = date;
  currDate.setFullYear(currDate.getFullYear() - years);
  return formatInput(currDate);
}

export function isAdult(dateOfBirth: string, fromDate?: string) {
  const partnerAge = calculateAge(dateOfBirth, fromDate);

  return partnerAge >= ADULTHOOD_AGE;
}

export function getFirstOfNextMonth(): string {
  const firstOfNextMonth = setDate(addMonths(new Date(), 1), 1);
  return formatInput(firstOfNextMonth);
}

/** Returns number of years */
export function getDifferenceInYears(date: string): number {
  const years = differenceInYears(new Date(), parseISO(date));
  return years;
}

export function getMinimumDateForBirth() {
  return removeYearsFromDate(new Date(), 102);
}
