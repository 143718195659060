import classNames from 'classnames';

import { FormControl, Typography } from '@mui/material';
import MUICheckbox from '@mui/material/Checkbox';
import { colors } from 'styles/colors';

import { Form } from 'lkh-portal-ui-library';
import { CheckboxProps } from 'lkh-portal-ui-library/dist/components/Checkbox/Checkbox';

const { Field } = Form.Components;

type DisclaimerCheckboxProps = {
  componentKey: string;
  onChange?: (value: boolean) => void;
} & CheckboxProps;

export const DisclaimerCheckbox = ({
  className = '',
  componentKey,
  description = '',
  disabled,
  onChange
}: DisclaimerCheckboxProps) => {
  const {
    onChange: formOnChange,
    hasError,
    message,
    value,
    isDirty,
    setIsDirty
  } = Form.hooks.useFormComponent(componentKey);

  const handleOnChange = () => {
    setIsDirty();
    onChange?.(!value);
    formOnChange(!value);
  };

  const isError = hasError && isDirty;
  return (
    <Field error={isError} message={message} className="p-0">
      <div
        className={classNames(
          'flex items-start justify-start gap-x-[24px] rounded-[8px] p-[24px]',
          className,
          value ? 'bg-primary-20' : 'bg-surface-60'
        )}
      >
        <div>
          <FormControl error={isError} disabled={disabled}>
            <MUICheckbox
              checked={!!value}
              onChange={handleOnChange}
              sx={
                isError
                  ? {
                      color: colors.danger[60],
                      '&.Mui-checked': {
                        color: colors.danger[60]
                      }
                    }
                  : {}
              }
              data-testid="checkbox-icon"
            />
          </FormControl>
        </div>
        <div>
          <Typography variant="bodyMDRegular" className="text-text-80">
            {description}
          </Typography>
        </div>
      </div>
    </Field>
  );
};
