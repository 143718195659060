import { useTranslation } from 'react-i18next';
import { formatGermanDate } from 'utils/dates';
import { formatBoolean, optionalValue } from 'utils/formatters';
import { HorizontalSeparator } from '../HorizontalSeparator/HorizontalSeparator';
import { HealthQuestionsProps } from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';
import Typography from '@mui/material/Typography';

export const HealthQuestions = ({ questions }: HealthQuestionsProps) => {
  const { t } = useTranslation('view360', { keyPrefix: 'insuredPerson.health' });

  return (
    <>
      {(questions || []).map((question) => {
        const renderQuestion = () => (
          <div className="row  my-s">
            <div className="col">
              <Typography variant="bodyMDBold">{question.text}</Typography>
            </div>
            <div className="col">
              <Typography variant="bodyMDRegular">{question.answer}</Typography>
            </div>
          </div>
        );

        const renderDetails = () => (
          <>
            {(question.details || []).map((detail, index) => (
              <div className="row m-v-16 p-16 bg-background" key={detail.diagnosis}>
                <div className="col reset-container">
                  <div className="row m-b-8">
                    <div className="col">
                      <Typography variant="bodyMDBold">{`${t('questions.detail')} ${
                        index + 1
                      }`}</Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.diagnose')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">{detail.diagnosis}</Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.treatmentType')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {optionalValue(detail.treatmentType)}
                      </Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.treatmentDuration')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">{`${optionalValue(
                        detail.treatmentStart,
                        formatGermanDate
                      )} - ${optionalValue(detail.treatmentEnd, formatGermanDate)}`}</Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.operation')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {optionalValue(detail.hasOperation, formatBoolean)}
                      </Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.consenquences')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {optionalValue(detail.hasConsenquences, formatBoolean)}
                      </Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.consenquencesDate')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {optionalValue(detail.symptomsFreeFrom, formatGermanDate)}
                      </Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.operation')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {optionalValue(detail.hasOperation, formatBoolean)}
                      </Typography>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {t('questions.attributes.operationDate')}
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography variant="bodyMDRegular">
                        {optionalValue(detail.operationDate, formatGermanDate)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        );

        return (
          <div key={question.id}>
            {renderQuestion()}
            {renderDetails()}
            <HorizontalSeparator className="m-v-16" />
          </div>
        );
      })}
    </>
  );
};
