import Typography from '@mui/material/Typography';
import { LegalFooter } from 'components/LegalFooter';
import TooltipInfo from 'components/TooltipInfo';
import { useConfigContext } from 'contexts/ConfigContext';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { WizardSlot } from 'layout/wizard/slots';
import { Body, Form, PartnerForm, useApplicationHelpers } from 'lkh-portal-ui-library';
import { applicationField, partnerField } from 'models';
import { PersonRoleEnum } from 'models/extension-generated';
import { NavigationSection } from 'pages/DigitalOrderPage/components/NavigationSection/NavigationSection';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getIbanInfo } from 'utils/bankDetails';

const { Input } = Form.Components;

const getKeys = (id: string) => ({
  // Personal Data
  genderKey: partnerField(id, 'gender'),
  salutationKey: partnerField(id, 'salutation'),
  titleKey: partnerField(id, 'title'),
  firstNameKey: partnerField(id, 'firstname'),
  lastNameKey: partnerField(id, 'lastname'),
  birthDateKey: partnerField(id, 'birthDate'),
  fundsFromGZKey: partnerField(id, 'applicationInformation.fundsFromGZ'),
  employmentTypeKey: partnerField(id, 'applicationInformation.employmentGroup'),
  // Marital status
  maritalStatusKey: partnerField(id, 'maritalStatus'),
  // GermanAddressForm
  livingInGermanyKey: partnerField(id, 'livingInGermany'),
  // AddressForm
  streetKey: partnerField(id, 'permanentResidence.street'),
  houseNumberKey: partnerField(id, 'permanentResidence.houseNumber'),
  postalCodeKey: partnerField(id, 'permanentResidence.postalCode'),
  cityKey: partnerField(id, 'permanentResidence.city'),
  countryKey: partnerField(id, 'permanentResidence.country'),
  // ForeignAddressForm
  hasForeignResidenceKey: partnerField(id, 'hasLivedInForeignCountry'),
  foreignCountryKey: partnerField(id, 'foreignCountry'),
  // Contact
  phoneKey: partnerField(id, 'permanentResidence.phone'),
  emailKey: partnerField(id, 'permanentResidence.email'),
  // Application information
  applicationStartKey: applicationField('applicationStart'),
  insuranceNumberLKHKey: partnerField(id, 'applicationInformation.insuranceNumberLKH'),
  agentIdKey: applicationField('agentId'),
  subAgentIdKey: applicationField('subAgentId'),
  externalId: applicationField('externalId'),
  // Bank details
  ibanKey: partnerField(id, 'bankDetails.iban'),
  bankNameKey: partnerField(id, 'bankDetails.bankName'),
  bicNumberKey: partnerField(id, 'bankDetails.bicNumber'),
  hasPaymentAuthorizationKey: partnerField(id, 'bankDetails.hasPaymentAuthorization')
});

export const InsuranceHolderStep = () => {
  const { t } = useTranslation('insuranceHolderStep');
  const [searchParams] = useSearchParams();

  const { state, dispatch } = useHealthInsuranceContext();
  const { getSinglePartnerByRole, isHolderInsured } = useApplicationHelpers(state.model);
  const agentId = searchParams.get('vermittlerId')?.toLowerCase();
  const subAgentId = searchParams.get('untervermittlerId')?.toLowerCase();
  const externeId = searchParams.get('externeId')?.toLowerCase();
  const holder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const isInsured = isHolderInsured(holder);
  const holderId = holder?.id || 'undefined';
  const payerId = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR)?.id || 'undefined';
  const holderKeys = getKeys(holderId);

  return (
    <WizardSlot.Main>
      <div className="mx-[8px] s:mx-[80px]">
        <div className="bg-white s:px-m s:p-[32px]">
          <Typography
            variant="headlineXXXLGBold"
            className="text-secondary-600 text-center s:text-left m-[0px] py-m"
          >
            {t('section.policyholder.title')}
          </Typography>
        </div>
        <Form state={state} dispatch={dispatch}>
          <div className="layout-res -mt-[72px]">
            <PartnerForm
              config={{
                useConfigContext: useConfigContext,
                useInsuranceContext: useHealthInsuranceContext
              }}
              partnerId={holderId}
              hasPersonalData={{ disabled: isInsured }}
              hasMaritalStatus
              hasAddress={{ disabled: isInsured }}
              hasForeignAddress={{ disabled: isInsured }}
              hasContact
              hasLKHNumber
            />
            <div className="grid-mx-res my-[24px]">
              <div className="flex justify-start items-center space-x-2">
                <div>
                  <Typography variant="bodyLGBold">{t('agent.title')}</Typography>
                </div>
                <div>
                  <TooltipInfo text={<Body regular>{t('agent.tooltip')}</Body>} />
                </div>
              </div>
            </div>
            <div className="grid-res-2">
              <div>
                <Input
                  disabled={!!agentId}
                  componentKey={holderKeys.agentIdKey}
                  label={t('agent.number.label')}
                />
              </div>
              <div>
                <Input
                  disabled={!!subAgentId}
                  componentKey={holderKeys.subAgentIdKey}
                  label={t('agent.subNumber.label')}
                />
              </div>
              <div>
                <Input
                  disabled={!!externeId}
                  componentKey={holderKeys.externalId}
                  label={t('agent.externalNumber.label')}
                />
              </div>
            </div>
          </div>

          <div className="layout-res">
            <PartnerForm
              config={{
                useConfigContext: useConfigContext,
                useInsuranceContext: useHealthInsuranceContext
              }}
              getIbanInfo={getIbanInfo}
              partnerId={payerId}
              hasBankDetails
            />
          </div>
          <div className="text-[12px] font-[400] leading-[18px] tracking-normal text-lkh-text-info">
            {t('common:fieldRequired')}
          </div>
        </Form>
      </div>
      <NavigationSection />
      <LegalFooter />
    </WizardSlot.Main>
  );
};
