import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { colors } from 'styles/colors';

export const IconLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.0004 6L15.4004 7.4L10.8004 12L15.4004 16.6L14.0004 18L8.00039 12L14.0004 6Z"
        fill={colors.text[60]}
      />
    </SvgIcon>
  );
};
