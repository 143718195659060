import Typography from '@mui/material/Typography';
import { Markdown } from 'components/Markdown';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import {
  Form,
  Icon,
  InformationCircleIcon,
  Tooltip,
  formModelGet,
  useExtendedReducer
} from 'lkh-portal-ui-library';
import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { partnerField } from 'models';
import {
  Application,
  Partner,
  Question,
  QuestionTypeEnum,
  SubQuestion
} from 'models/extension-generated';
import i18n from 'services/translation';
import { CustomMonthYearPicker } from './components/CustomMonthYearPicker';

const { Input, YesNo, Radio } = Form.Components;

/**
 * Find all subquestions that depend on the current subquestion
 * @param subQuestion
 * @param subQuestions
 * @returns
 */

const getSubQuestionField = (
  partnerId: string,
  questionsId: string,
  detailId: string,
  attribute: string
): string => {
  return partnerField(
    partnerId,
    `applicationInformation.health[${questionsId}].details[${detailId}].${attribute}` as RecursiveKeyOf<Partner>
  );
};

const resolveRightOperand = (rightOperand: string, rightOperandType: QuestionTypeEnum) => {
  switch (rightOperandType) {
    case QuestionTypeEnum.CLOSED_QUESTION:
      return Boolean(parseInt(rightOperand));
    case QuestionTypeEnum.ENUMERATED_QUESTION:
      return rightOperand.split(',');
    default:
      return rightOperand;
  }
};

const evaluateCondition = (
  model: Application,
  leftOperand: string,
  rightOperand: string,
  questionId: string,
  partnerId: string,
  subQuestionId: string,
  rightOperandType: QuestionTypeEnum,
  questions: SubQuestion[]
) => {
  // get value of subquetion for this main question from data model for left and right operand
  const leftOperandAttributeKey = questions.find(({ id }) => id === leftOperand)?.attributeKey;

  if (!leftOperandAttributeKey) return false;
  const leftOperandValue = formModelGet(
    model,
    getSubQuestionField(partnerId, questionId, subQuestionId, leftOperandAttributeKey)
  );
  const rightOperandValue = resolveRightOperand(rightOperand, rightOperandType);

  if (!leftOperandValue || !rightOperandValue) return false;

  if (Array.isArray(rightOperandValue)) {
    return rightOperandValue.includes(leftOperandValue);
  }
  return leftOperandValue === rightOperandValue;
};

const renderSubQuestion = ({
  subQuestion,
  key,
  onChange
}: {
  subQuestion: SubQuestion;
  key: string;
  onChange: (value: any) => void;
}) => {
  const { hasError, message } = Form.hooks.useFormComponent(key);

  const handleOnChange = (value: any) => {
    onChange(value.value);
  };
  const handleOnInputChange = (value: any) => {
    onChange(value);
  };

  return (
    <div>
      {subQuestion.headline && (
        <>
          <div className="mb-m s:mb-s">
            <Typography variant="bodyMDBold">{subQuestion.headline}</Typography>
          </div>
        </>
      )}

      <div className="flex flex-col s:flex-row s:items-center">
        <div className="pb-m s:pb-[0px] s:basis-5/12 s:grow-0 s:shrink-0 flex items-center">
          <Typography variant="bodySMRegular">{subQuestion.text}</Typography>
          <div>
            {subQuestion.tooltip && (
              <Tooltip
                tooltipContent={
                  <div className="max-w-[50vw] ">
                    <Typography variant="bodySMRegular">
                      <Markdown markdown={subQuestion.tooltip} />
                    </Typography>
                  </div>
                }
              >
                <div
                  className="mt-[2px]"
                  style={{
                    color: '#00aca9'
                  }}
                >
                  <Icon icon={<InformationCircleIcon />} className="ml-s" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {subQuestion.questionType === QuestionTypeEnum.CLOSED_QUESTION && (
          <YesNo componentKey={key} onChange={handleOnChange} />
        )}
        {subQuestion.questionType === QuestionTypeEnum.ENUMERATED_QUESTION && (
          <Radio
            componentKey={key}
            options={(subQuestion.possibleValues || []).map((value) => {
              return {
                label: i18n.t(`common:wizard.healthSection.${value}`),
                value
              };
            })}
            onChange={handleOnChange}
          />
        )}
        {subQuestion.questionType === QuestionTypeEnum.TEXT_QUESTION && (
          <Input
            componentKey={key}
            placeholder={subQuestion.hint}
            label={subQuestion.hint}
            onChange={handleOnInputChange}
          />
        )}
        {subQuestion.questionType === QuestionTypeEnum.DATE_QUESTION && (
          <div className="basis-4/12">
            <CustomMonthYearPicker
              onChange={(value) => {
                onChange(value);
              }}
              componentKey={key}
            />
          </div>
        )}
      </div>
      {hasError && (
        <Typography component="div" variant="bodySMRegular" className="inline text-error-700">
          {message || 'hasError'}
        </Typography>
      )}
    </div>
  );
};

export const QuestionDetails = ({
  partnerId,
  questionId,
  detailId,
  subQuestion,
  question,
  mainQuestionValue
}: {
  partnerId: string;
  questionId: string;
  detailId: string;
  mainQuestionValue: boolean;
  subQuestion: SubQuestion;
  question: Question;
}) => {
  const { state, dispatch } = useHealthInsuranceContext();
  const { resetDetails } = useExtendedReducer(dispatch);
  const { rightOperand, leftOperand, rightOperandType, attributeKey = '' } = subQuestion;
  const hasOperandCondition = rightOperand && leftOperand && rightOperandType;
  const subQuestionKey = getSubQuestionField(partnerId, questionId, detailId, attributeKey);

  const resolvedCondition =
    hasOperandCondition &&
    evaluateCondition(
      state.model,
      leftOperand,
      rightOperand,
      questionId,
      partnerId,
      detailId,
      rightOperandType,
      question.subQuestions || []
    );
  const getDependencies = (subQuestion: SubQuestion, subQuestions: Array<SubQuestion>) => {
    const { id } = subQuestion;
    const dependantSubQuestions = subQuestions.filter(({ leftOperand }) => {
      return leftOperand === id;
    });

    return dependantSubQuestions;
  };

  const handleChangeHealthQuestionDetail = () => {
    const dependantSubQuestions = getDependencies(subQuestion, question.subQuestions || [])
      .map(({ attributeKey }) => attributeKey || '')
      .filter(Boolean);

    resetDetails({
      detailId,
      partnerId,
      questionId,
      attributes: dependantSubQuestions
    });
  };

  if (!mainQuestionValue) return <></>;

  return (
    <div>
      {!hasOperandCondition && (
        <>
          {renderSubQuestion({
            key: subQuestionKey,
            subQuestion,
            onChange: handleChangeHealthQuestionDetail
          })}
        </>
      )}
      {resolvedCondition && (
        <>
          {renderSubQuestion({
            key: subQuestionKey,
            subQuestion,
            onChange: handleChangeHealthQuestionDetail
          })}
        </>
      )}
    </div>
  );
};
