import classNames from 'classnames';

import { DarkTooltip } from 'lkh-portal-ui-library';
import { Tariff, TariffCalculationResult } from 'models/extension-generated';

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { formatPrice } from 'utils/string';
import { TariffDescription } from '../../TariffDescription';

type TariffRowProps = {
  tariff: Tariff;
  calculation?: TariffCalculationResult;
  tariffsList: Array<Tariff>;
  isMobile: boolean;
};

export const TariffRow = ({ tariff, calculation, tariffsList, isMobile }: TariffRowProps) => {
  const getTariffDescription = (id: string) => {
    const rawDescription = tariffsList.find((tariff) => tariff.id === id)?.description;
    return <TariffDescription description={rawDescription!} />;
  };

  function hasRiskPremium(calculation?: TariffCalculationResult): boolean {
    const risk = calculation?.calculationMonthly?.riskSurcharges;
    if (risk && risk > 0) {
      return true;
    }
    return false;
  }
  const tariffPrice = calculation?.calculationMonthly?.amount;

  return (
    <div className="flex justify-between py-[4px]">
      <DarkTooltip
        children={
          <Chip
            label={tariff.id}
            className="rounded-[8px] text-white-100"
            variant="filled"
            color="primary"
          />
        }
        title={getTariffDescription(tariff.id)}
        arrow
        placement="left"
      />

      <div className="flex items-center">
        {hasRiskPremium(calculation) && (
          <Typography
            variant="bodyMDRegular"
            className={classNames('p-r-8 m-r-8 text-neutral-500')}
          >
            {formatPrice(calculation?.calculationMonthly?.riskSurcharges)}
          </Typography>
        )}
        <Typography
          variant="bodyMDRegular"
          className={`m-0 ${isMobile ? 'text-white' : 'text-black'}`}
        >
          {tariffPrice && formatPrice(calculation?.calculationMonthly?.amount)}
          {!tariffPrice && '-,--'}
        </Typography>
      </div>
    </div>
  );
};
