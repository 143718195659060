import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { colors } from 'styles/colors';

export const IconHomeHealth = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11 17H14V14.5H16.5V11.5H14V9H11V11.5H8.5V14.5H11V17ZM4.5 21V9L12.5 3L20.5 9V21H4.5ZM6.5 19H18.5V10L12.5 5.5L6.5 10V19Z"
        fill={colors.highlight[80]}
      />
    </SvgIcon>
  );
};
