import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { colors } from 'styles/colors';

export const IconRight = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill={colors.text[60]} />
    </SvgIcon>
  );
};
