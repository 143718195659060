import classNames from 'classnames';
import { useConfigContext } from 'contexts/ConfigContext';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { WizardSlot } from 'layout/wizard/slots';
import {
  Body,
  Form,
  PartnerForm,
  TabGroup,
  TabList,
  useApplicationHelpers
} from 'lkh-portal-ui-library';
import { applicationField, partnerField } from 'models';
import { HealthQuestionsForm } from 'pages/DigitalOrderPage/components/HealthQuestionsForm';
import { useTranslation } from 'react-i18next';
import { TariffSection } from '../TariffSection';
import { HealthDisclaimer } from './HealthDisclaimer/HealthDisclaimer';
import styles from './index.module.scss';

import { LegalFooter } from 'components/LegalFooter';
import { Partner, PersonRoleEnum } from 'models/extension-generated';
import { NavigationSection } from 'pages/DigitalOrderPage/components/NavigationSection/NavigationSection';
import { useRef } from 'react';
import Typography from '@mui/material/Typography';

const getPartnerKeys = (partnerId: string) => ({
  // Personal Data
  // InsuredPersonForm
  genderKey: partnerField(partnerId, 'gender'),
  salutationKey: partnerField(partnerId, 'salutation'),
  titleKey: partnerField(partnerId, 'title'),
  firstNameKey: partnerField(partnerId, 'firstname'),
  lastNameKey: partnerField(partnerId, 'lastname'),
  birthDateKey: partnerField(partnerId, 'birthDate'),
  fundsFromGZKey: partnerField(partnerId, 'applicationInformation.fundsFromGZ'),
  employmentTypeKey: partnerField(partnerId, 'applicationInformation.employmentGroup'),
  professionKey: partnerField(partnerId, 'applicationInformation.profession'),
  // InsuranceHolderRelation
  relationshipKey: partnerField(partnerId, 'applicationInformation.relationshipToHolder'),
  // GermanAddressForm
  livingInGermanyKey: partnerField(partnerId, 'livingInGermany'),
  // AddressForm
  streetKey: partnerField(partnerId, 'permanentResidence.street'),
  houseNumberKey: partnerField(partnerId, 'permanentResidence.houseNumber'),
  postalCodeKey: partnerField(partnerId, 'permanentResidence.postalCode'),
  cityKey: partnerField(partnerId, 'permanentResidence.city'),
  // ForeignAddressForm
  hasForeignResidenceKey: partnerField(partnerId, 'hasLivedInForeignCountry'),
  foreignCountryKey: partnerField(partnerId, 'foreignCountry'),
  // Health
  hasLegalObligationToNotify: partnerField(
    partnerId,
    'applicationInformation.hasLegalObligationToNotify'
  ),
  // Application
  applicationStartKey: applicationField('applicationStart')
});

export function InsuredPersonStep() {
  const { t } = useTranslation('insuredPersonStep');
  const mainRef = useRef<HTMLDivElement>(null);
  const { config: stateConfig } = useConfigContext();
  const { state, dispatch, partnerId, selectPartner } = useHealthInsuranceContext();
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const partnerKeys = getPartnerKeys(partnerId);
  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);

  const tabGroupOptions: Array<TabList> = insuredPartners.map((partner) => {
    const hasPartnerError = (partner: Partner): boolean => {
      return (
        Array.from(state.validationResults.keys()).filter((key) => key.includes(partner.id!))
          .length > 0
      );
    };
    const hasError = hasPartnerError(partner);

    const name = (() => {
      if (!partner.lastname && !partner.firstname) return t('Neue versicherte Person');
      let nameText = '';
      if (partner.lastname) nameText += partner.lastname;
      if (partner.lastname && partner.firstname) nameText += ', ';
      if (partner.firstname) nameText += partner.firstname;

      return nameText;
    })();
    return {
      id: partner.id || '',
      counter: 0,
      label: (
        <div
          className={classNames({
            ['border-b-primary border-b']: partner.id === partnerId
          })}
        >
          <Body
            bold={partner.id === partnerId}
            className={classNames({
              ['text-error-700']: hasError
            })}
            small
          >
            {name}
          </Body>
        </div>
      )
    };
  });

  return (
    <WizardSlot.Main ref={mainRef}>
      <div className="bg-white s:px-[80px] s:py-[32px]">
        <Typography
          variant="headlineXXXLGBold"
          className="text-secondary-600 text-center s:text-left m-[0px] py-m"
        >
          {t('title')}
        </Typography>
      </div>
      {insuredPartners.length > 1 && (
        <div className={styles.wrapperInner}>
          <TabGroup
            selected={partnerId}
            showDownloadIcon={false}
            list={tabGroupOptions}
            onTabChange={(id) => {
              selectPartner(String(id));
            }}
          />
        </div>
      )}
      <Form state={state} dispatch={dispatch}>
        <TariffSection
          partnerKeys={{
            birthDateKey: partnerKeys.birthDateKey,
            applicationStartKey: partnerKeys.applicationStartKey
          }}
        />
        <div className={classNames('layout-res', styles.questionWrapperInner)}>
          <div className="grid-mx-res">
            <Typography variant="headlineXXXLGBold">
              {t('insuredPeople.steps.personalData.title', { ns: 'wizardPrivateHealthInsurance' })}
            </Typography>
          </div>
          <PartnerForm
            config={{
              useConfigContext: useConfigContext,
              useInsuranceContext: useHealthInsuranceContext
            }}
            partnerId={partnerId}
            hasPersonalData
            hasEmployment
            hasAddress
            hasForeignAddress
            hasHolderRelation
          />
        </div>

        <div className="layout-res s:mx-[80px]">
          <div className="grid-mx-res">
            <Typography variant="bodyLGBold">
              {t('insuredPeople.steps.health.title', { ns: 'wizardPrivateHealthInsurance' })}
            </Typography>
          </div>

          <div className="grid-res">
            <HealthDisclaimer componentKey={partnerKeys.hasLegalObligationToNotify} />
          </div>

          <div className="grid-mx-res">
            <HealthQuestionsForm partnerId={partnerId} config={stateConfig} state={state} />
          </div>
        </div>
        <div className="grid-mx-res s:mx-[80px] text-[12px] font-[400] leading-[18px] tracking-normal text-lkh-text-info">
          {t('common:fieldRequired')}
        </div>
      </Form>
      <NavigationSection
        onPersonSwitch={() => {
          setTimeout(() => {
            mainRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
          }, 500);
        }}
      />
      <LegalFooter />
    </WizardSlot.Main>
  );
}
