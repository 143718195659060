import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useTranslation, Trans } from 'react-i18next';
import { downloadData, downloadFormByReferenceId } from 'utils/fetchDocument';
import Typography from '@mui/material/Typography';
import { DisclaimerCheckbox } from '../../SummaryStep/components/DisclaimerCheckbox';

type HealthDisclaimerProps = {
  componentKey: string;
};

export const HealthDisclaimer = ({ componentKey }: HealthDisclaimerProps): JSX.Element => {
  const { t } = useTranslation('wizardHealth');
  const { state, reducer } = useHealthInsuranceContext();
  const { applicationStart } = state.model;
  const { updateValue } = reducer;

  async function handleDownload(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    const response = await downloadFormByReferenceId('K06_31', applicationStart || '');
    downloadData(response.data, 'Mitteilung über die Folgen einer Anzeigepflichtverletzung.pdf');
  }

  function handleConsentChange(value: boolean) {
    if (!value) {
      updateValue({ key: componentKey, value: null });
    }
  }

  return (
    <DisclaimerCheckbox
      componentKey={componentKey}
      onChange={handleConsentChange}
      description={
        <div>
          <Typography component={'div'} variant="bodyMDBold" className="text-text-100 m-0">
            {t('section.legal.title')}
          </Typography>
          <Typography component={'div'} variant="bodyMDRegular" className="text-text-100 mt-m">
            <b className="text-neutral-1000">{t('section.legal.boldText')}</b>
            <Trans
              i18nKey="wizardHealth:section.legal.text"
              components={[
                <a
                  onClick={handleDownload}
                  className="text-primary-80 cursor-pointer inline underline"
                />
              ]}
            />
          </Typography>
        </div>
      }
    />
  );
};
