import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { formModelGet } from 'lkh-portal-ui-library';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getMonths, getYears } from './utils';
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@mui/material';

export type MonthYear = {
  month?: number;
  year?: number;
};

export const CustomMonthYearPicker = ({
  onChange,
  componentKey
}: {
  onChange: (value: string) => void;
  componentKey: string;
}) => {
  const { t } = useTranslation('common');
  const { state, reducer } = useHealthInsuranceContext();
  const value = formModelGet(state.model, componentKey);
  const currentYear = new Date().getFullYear();
  const [date, setDate] = useState<MonthYear>({ month: undefined, year: undefined });

  // Side Effect - update date when value changes
  useEffect(() => {
    if (!value) {
      setDate({ month: undefined, year: undefined });
      return;
    }
    const date = moment(value, 'YYYY-MM-DD');
    const month = date.month() + 1;
    const year = date.year();

    setDate({ month, year });
  }, [value]);

  const years = getYears(currentYear);
  const months = getMonths();

  const handleOnChange = (key: keyof MonthYear, value: string) => {
    const val = Number(value);
    const { month, year }: MonthYear = { ...date, [key]: val };

    setDate((prev) => ({
      ...prev,
      [key]: val
    }));

    // change callback in format YYYY-MM-DD
    if (month && year) {
      const formattedDate = (() => {
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${year}-${formattedMonth}-01`;
      })();
      onChange?.(formattedDate);
      reducer.updateValue({
        key: componentKey,
        value: formattedDate
      });
    }
  };

  return (
    <div className="flex gap-s flex-row s:justify-start w-full">
      <TextField
        select
        label={t('month')}
        value={date.month || ''}
        onChange={(e) => handleOnChange('month', e.target.value)}
        variant="standard"
        className="w-[100px]"
      >
        {months.map((month) => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label={t('year')}
        value={date.year || ''}
        onChange={(e) => handleOnChange('year', e.target.value)}
        variant="standard"
        className="w-[100px]"
      >
        {years.map((year) => (
          <MenuItem key={year.value} value={year.value}>
            {year.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};
