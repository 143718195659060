import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { formModelSet } from 'lkh-portal-ui-library';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useWizardNavigation } from '../../../hooks/useWizardNavigation';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useApplicationValidation } from 'hooks/useApplicationValidation';
import { partnerField } from 'models';
import { ApplicationService, NeedSituationEnum, PersonRoleEnum } from 'models/extension-generated';
import { EVENT_IDS } from 'pages/DigitalOrderPage/constants';
import { WizardRoutesEnum } from 'utils/actions';
import { ApiError } from 'models/portal-generated';
import { ContactSupportDialog } from 'components/ContactSupportDialog';

type ContinueButtonProps = {
  title: string;
  isIcon: boolean;
};

export const ContinueButton = ({ title }: ContinueButtonProps) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isContactSupportDialogOpen, setContactSupportDialogOpen] = useState(false);
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { next, ScreenOrder, currentScreen } = useWizardNavigation();
  const { reducer, state, partnerId } = useHealthInsuranceContext();

  const { isValidating, validateNextPage } = useApplicationValidation(state, reducer, partnerId);

  const isProcessing = isSubmitting || isValidating;

  const handleContinueClick = async () => {
    try {
      const index = ScreenOrder.indexOf(currentScreen) + 1;
      const nextPage = ScreenOrder[index];
      const validationResult = await validateNextPage(nextPage);
      const hasNoErrors = validationResult.size === 0;
      const partnerHasAcceptsToWaiveAdviceNotConfirmed = validationResult.has(
        partnerField(partnerId, 'applicationInformation.acceptsToWaiveAdvice')
      );

      if (partnerHasAcceptsToWaiveAdviceNotConfirmed) {
        setContactSupportDialogOpen(true);
      }

      if (hasNoErrors) {
        // if current screen is summary screen then submit application
        if (currentScreen === WizardRoutesEnum.Summary) {
          setIsSubmitting(true);
          const cloned = { ...cloneDeep(state.model) };
          const insuredPersons = cloned.partners.filter(({ roles }) =>
            roles.includes(PersonRoleEnum.INSURED_PERSON)
          );
          const nonPaymentContributors = cloned.partners.filter(
            ({ roles }) => !roles.includes(PersonRoleEnum.PAYMENT_CONTRIBUTOR)
          );
          const setDefaultNeedSituationForInsuredPersons = () => {
            insuredPersons.forEach(({ id }) => {
              const needSituationPath = partnerField(
                id || '',
                'applicationInformation.tariffInformation.needSituation'
              );
              formModelSet(cloned, needSituationPath, NeedSituationEnum.ZVGKV);
            });
          };
          const pruneBankDetailsForNonPaymentContributors = () => {
            nonPaymentContributors.forEach(({ id }) => {
              const bankDetailPath = partnerField(id || '', 'bankDetails');
              formModelSet(cloned, bankDetailPath, undefined);
            });
          };

          setDefaultNeedSituationForInsuredPersons();
          pruneBankDetailsForNonPaymentContributors();

          await ApplicationService.submitPublicApplication({
            requestBody: cloned
          });
          navigate(`/${WizardRoutesEnum.Done}/${state.model.id}`);
          return;
        }
        next();
      }
    } catch (e) {
      if (e instanceof Error || e instanceof ApiError) {
        toast.warning(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderIcon = () => {
    if (isProcessing || isValidating) {
      return (
        <span className="px-[3px]">
          <CircularProgress color="inherit" size={16} />
        </span>
      );
    }

    return <ArrowForwardOutlinedIcon />;
  };

  return (
    <>
      {isContactSupportDialogOpen && (
        <ContactSupportDialog
          isDialogOpen={isContactSupportDialogOpen}
          onDialogClose={() => setContactSupportDialogOpen(false)}
        />
      )}
      <Button
        id={`${currentScreen}_${EVENT_IDS.NEXT}`}
        color="primary"
        variant="contained"
        size="large"
        onClick={handleContinueClick}
        endIcon={renderIcon()}
        disabled={isProcessing}
      >
        {isSubmitting && t('actions.submitting')}
        {isValidating && t('actions.validating')}
        {!isSubmitting && !isValidating && title}
      </Button>
    </>
  );
};
