import Typography from '@mui/material/Typography';
import { Icon } from 'lkh-portal-ui-library';
import { useTranslation } from 'react-i18next';
import styles from './NoHealthQuestions.module.scss';
import { NoQuestionsPictogram } from './NoHealthQuestionsPictogram';

export const NoHealthQuestions = () => {
  const { t } = useTranslation('wizardHealth');
  return (
    <section className={`flex flex-col items-center text-center ${styles.section}`}>
      <Icon className="mb-s" color="lkh-color-primary-80" icon={<NoQuestionsPictogram />} />
      <Typography variant="headlineXXLGRegular" className="text-logo-500">
        {t('section.noQuestions.message')}
      </Typography>
    </section>
  );
};
