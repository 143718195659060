import { addPartnerButtonPages, getNextButtonText, noNextButtonIconPages } from 'utils/actions';
import { useWizardNavigation } from 'hooks/useWizardNavigation';
import { AddPartnerButton } from 'components/Wizard/AddPartnerButton';
import { BackButton, ContinueButton } from 'components/Wizard';
import { ValidationError } from 'components/ValidationError';

export const NavigationSection = ({ onPersonSwitch }: { onPersonSwitch?: () => void }) => {
  const { currentScreen } = useWizardNavigation();
  return (
    <div className="flex flex-col s:flex-row s:justify-between items-center bg-white px-m s:px-[80px] py-l s:py-m space-y-6 s:space-y-0">
      {addPartnerButtonPages.includes(currentScreen) ? (
        <AddPartnerButton onPersonSwitch={onPersonSwitch} />
      ) : (
        <BackButton />
      )}
      <div className="flex flex-nowrap items-center justify-items-end w-full space-x-[16px] min-w-0">
        <div className="flex-grow min-w-0">
          <ValidationError />
        </div>

        <div className="flex-shrink-0">
          <ContinueButton
            title={getNextButtonText(currentScreen)}
            isIcon={!noNextButtonIconPages.includes(currentScreen)}
          />
        </div>
      </div>
    </div>
  );
};
